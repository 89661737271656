<script>
  import { startCyclicChange } from "../../js/utils.js";

  export default {
    name: "CounterBtn",
    components: {
      Confetti: () => import("../art/Confetti.vue")
    },
    props: {
      button: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        showLoader: this.button.initialAnimation,
        currentImageIndex: 0,
        imageIntervalId: null,
        timeOutId: null,
        conffetiTimeOut: null,
        showAnimation: false,
      };
    },
    methods: {
      setImageChange() {
        const imagesLength = this.button.images.length

        if (imagesLength <= 1) {
          return;
        }

        clearInterval(this.imageIntervalId);

        this.imageIntervalId = startCyclicChange(imagesLength, 1700, (newIndex) => {
          this.currentImageIndex = newIndex;
        });
      },
      setShowAnimation(){
        if(this.showAnimation){
          return;
        }

        this.showAnimation = true;

        clearTimeout(this.conffetiTimeOut)
        this.conffetiTimeOut = setTimeout(() => {
          this.showAnimation = false;
        }, 5000)
      },  
      startLoader(duration = 0) {
        const loaderTime = duration;
        this.showLoader = true;
        this.timeOutId = setTimeout(() => {
          this.showLoader = false;
          this.setImageChange();
        }, loaderTime);
      },
    },
    beforeDestroy() {
      clearInterval(this.imageIntervalId);
      clearTimeout(this.timeOutId);
    },
  };
</script>
<template>
  <button class="CounterBtn" :style="{ '--background': button.background }" @mouseenter="$emit('hovered')" @mouseleave="$emit('unHovered')" @touchstart="$emit('clicked')">
    <div v-if="showLoader" class="CounterBtn__loader"></div>
    <transition name="fade">
      <img :key="currentImageIndex" :src="showLoader ? button.initialImage : button.images[currentImageIndex]" alt="btn image" class="CounterBtn__image" />
    </transition>
    <div v-if="button.showMask" class="CounterBtn__mask"></div>
    <p v-if="!showLoader && button.text" :key="button.text" class="CounterBtn__title">
      {{ button.text }}
    </p>
    <div class="CounterBtn__slot" v-if="!showLoader">
      <slot name="counter-slide" :showLoader="showLoader"></slot>
    </div>
    <div class="CounterBtn__confettiWrapper" >
      <transition name="fade">
        <component :is="button.animation" v-if="showAnimation" />
      </transition>
    </div>
  </button>
</template>

<style lang="scss">
  .CounterBtn {
    position: relative;
    @include Flex();
    width: 77px;
    height: 77px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 0px 4px 0px #000000;
    background: var(--background);
    &__loader {
      position: absolute;
      width: 90%;
      height: 90%;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      z-index: 2;
      top: 5%;
      left: 5%;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-left: 2px solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        background: conic-gradient(#fbf3a6, #fde57f, #f1d371, #ca9b43, #b37826, #aa6b1c, #b57b29, #d1a54b, #fde57f, #fbee98, #fbf3a6);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
    }
    &__mask {
      position: absolute;
      width: 92%;
      height: 92%;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
    &__slot {
      position: absolute;
      width: 100%;
      top: 50%;
      color: $white;
    }
    &__title {
      position: absolute;
      bottom: 52%;
      left: 50%;
      transform: translateX(-50%);
      font-family: $newFont_medium;
      font-style: italic;
      font-weight: bold;
      line-height: normal;
      text-shadow: 0px 0px 1px #000000b6;
      color: $white;
      font-size: 9px;
      text-transform: uppercase;
    }
    &__confettiWrapper{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @include pocketWidth() {
      width: 85px;
      height: 85px;
      &__title {
        font-size: 10px;
      }
    }
    @include tabletWidth() {
      width: 93px;
      height: 93px;
      &__title {
        font-size: 11px;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
