<script>
  import CounterBtn from "../buttons/CounterBtn.vue";
  import { mapGetters } from "vuex";
  export default {
    components: {
      CounterBtn,
    },
    data() {
      return {
        isLoading: true,
        competitions: [],
      };
    },
    computed: {
      ...mapGetters("competitions", ["getCompetition"]),
      year() {
        return this.$route.params.year;
      },
      competitionSelected() {
        return this.$route.params.id;
      },
      competitionButtons() {
        const defaultImg = "https://new-web-site-bucket.s3.amazonaws.com/myspace/competitions/defaultImg.png";
        return this.competitions.map(({ _id, name, coverImage }) => ({
          _id: _id,
          name,
          images: this.competitionSelected === _id ? [coverImage || defaultImg] : [defaultImg],
          background:
            this.competitionSelected === _id
              ? "linear-gradient(90deg, #91693A 3%, #A07A43 10%, #C8A65D 24%, #DFBF6C 31%, #B48A42 50%, #BB944C 56%, #D1B169 65%, #F3DF96 77%, #F7E59C 79%, #E6D08C 81%, #C7AA6E 85%, #AF8D57 89%, #9D7846 93%, #936B3C 96%, #906739 100%)"
              : "#2B2D2E",
        }));
      },
    },
    watch: {
      year() {
        this.getYearCompetitions();
      },
    },
    methods: {
      async getYearCompetitions() {
        try {
          this.isLoading = true;

          const { data = {} } = await this.$axios.get(`/contest/years/${this.year}`);
          const { yearCompetitions = [] } = data;

          this.competitions = yearCompetitions;
          this.verifyCompetition();
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      },
      verifyCompetition() {
        if (this.competitionSelected || !this.competitions.length) {
          return;
        }

        const competitionToShow = this.competitions[this.competitions.length - 1];

        this.$router.push({ name: "CompetitionSelected", params: { id: competitionToShow._id } });
      },
    },
    beforeMount() {
      this.getYearCompetitions();
    },
  };
</script>

<template>
  <section class="yearCompetition">
    <div class="yearCompetition__nav">
      <router-link class="yearCompetition__wrapper" v-for="(competition, idx) in competitionButtons" :key="competition._id" :to="{ name: 'CompetitionSelected', params: { id: competition._id } }">
        <span class="yearCompetition__name">{{ competition.name }}</span>
        <div class="yearCompetition__button">
          <CounterBtn :button="competition" />
          <div class="yearCompetition__line" v-if="competitionButtons[idx + 1]" />
        </div>
      </router-link>
    </div>
    <router-view></router-view>
  </section>
</template>

<style lang="scss">
  .yearCompetition {
    .CounterBtn {
      width: 38px;
      height: 38px;
      .CounterBtn__image {
        width: 87%;
        height: 90%;
        position: unset;
      }
    }
    @include Flex(column);
    gap: $space-16;
    width: 100%;
    &__nav {
      display: flex;
      max-width: 100%;
      gap: $space-16;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
    &__wrapper {
      @include Flex(column);
      flex-shrink: 0;
      width: 150px;
      gap: $space-12;
      font-family: $newFont;
      text-transform: uppercase;
      white-space: nowrap;
    }
    &__name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #212121;
    }
    &__button {
      position: relative;
      margin-bottom: 2px;
    }
    &__line {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 38px;
      margin: auto;
      height: 2px;
      width: 128px;
      background: linear-gradient(
        90deg,
        #91693a 3%,
        #a07a43 10%,
        #c8a65d 24%,
        #dfbf6c 31%,
        #b48a42 50%,
        #bb944c 56%,
        #d1b169 65%,
        #f3df96 77%,
        #f7e59c 79%,
        #e6d08c 81%,
        #c7aa6e 85%,
        #af8d57 89%,
        #9d7846 93%,
        #936b3c 96%,
        #906739 100%
      );
    }
  }
</style>
